<template>
    <div class="service-table">
        <table style="width: 1500px">
            <tr>
                <th class="service-left">服务详情</th>
                <th class="service-right">委托方需求订单</th>
            </tr>
        </table>
        <table style="width: 1500px">
            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务名称</div></th>
                <td class="bold" style="width: 443px">
                    <div style="text-align: center">{{serviceInfo.title}}</div>
                </td>
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">需求名称</div></th>
                <td class="bold" style="width: 742px; border-right: none">
                    <el-input
                        type="textarea"
                        v-model="serviceInfo.name"
                        :autosize="true"
                        clearable>
                    </el-input>
                </td>
            </tr>

            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">价格范围</div></th>
                <td class="bold" style="width: 443px">
                    <div style="text-align: center; color: #EE3F4D" v-if="serviceInfo.maxPrice === 0">￥{{serviceInfo.minPrice}}</div>
                    <div style="text-align: center; color: #EE3F4D" v-else>￥{{serviceInfo.minPrice }} - {{serviceInfo.maxPrice}}</div>
                </td>
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0"></div>需求金额</th>
                <td class="bold" style="width: 742px; border-right: none">
                    <div class="select-box">
                        <el-input
                            v-model="serviceInfo.price"
                            :autosize="true"
                            clearable>
                        </el-input>
                    </div>
                </td>
            </tr>
            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务介绍</div></th>
                <td class="bold" style="width: 443px; ">
                    <div style="height: 600px; overflow: auto" v-html="serviceInfo.content"></div>
                </td>
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">需求内容</div></th>
                <td class="bold" style="width: 742px; border-right: none">
                    <Editor :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="serviceInfo.demand" ref="orderChild"></Editor>
                </td>
            </tr>
        </table>
        <table style="width: 1500px">
            <tr v-for="(item,index) in serviceInfo.otherSpecifications" :key="index" style="width: 1500px">
                <div v-if="item.type !== 6">
                    <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">{{item.key}}</div></th>

                    <td class="bold" style="width: 443px" v-if="item.type==1 || item.type==2">
                        <div v-if="item.userType==1" style="text-align: center">{{item.value}}</div>
	                    <el-input  type="textarea" style="width: 100%;height: 100%;" v-if="item.userType==2" :value="item.value"></el-input>
                    </td>

                    <td class="bold" style="width: 443px"  v-if="item.type==3 || item.type == 5"  >
                        <div style="text-align: center" v-if="item.userType==2">
                            <el-radio-group   v-model="item.radio" >
                                <el-radio :dibled ="item.userType == 1 "  v-for="(selectItem, selectIndex) in item.value" :key="selectIndex"  :label="selectItem.radio">{{selectItem.name}}</el-radio>
                            </el-radio-group>
                        </div>
	                    <div style="text-align: center" v-else>
		                    <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
			                    <span v-if="selectItem.radio==item.radio">{{selectItem.name}}</span>
		                    </div>
	                    </div>
                    </td>

                    <td class="bold" style="width: 443px" v-if="item.type==4">
                        <div style="text-align: center" v-if="item.userType==2">
                            <el-checkbox-group v-model="item.data">
                                <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
                                    <el-checkbox  :label="selectItem.radio">{{selectItem.name}}</el-checkbox>
                                </div>
                            </el-checkbox-group>
                        </div>
	                    <div style="text-align: center" v-else>
		                    <div v-for="(selectItem, selectIndex) in item.value" :key="selectIndex">
			                    <span v-if="item.selectList.includes(selectItem.radio)">{{ selectItem.name }}</span>
		                    </div>
	                    </div>
                    </td>

                    <td class="bold" style="width: 150px"  v-if="item.userType == 2">
                        <div class="select-box" >
                            <el-checkbox  v-if="item.type === 1 || item.type === 2 " v-model="item.status" :true-label="0" :false-label="1">选择</el-checkbox>
                            <div v-else  style="font-size: 14px">备注</div>
                        </div>
                    </td>

                    <td class="bold" style="border-right: none; width: 742px">
                        <el-input
	                        v-if ="item.userType == 2 "
                            type="textarea"
                            v-model="item.content"
                            :autosize="true"
                            style="border: none"
                            clearable>
                        </el-input>
                    </td>
                </div>
                <div v-else >
                    <th class="title" style="width: 148px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">{{item.key}}</div></th>
                    <td class="bold" style="width: 1335px; border-right: none">
                        <div style="width: 100%;"  v-if ="item.userType == 2 ">
                            <el-radio-group v-model="item.data" style="width: 100%;">
                                <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" :style="pindex==item.value.length-1?'':'border-bottom: 1px solid #BABABA'">
                                    <div style="width: 14%; overflow: hidden; float: left;text-align: center;">
                                        <el-radio :label="p.radio">
                                            <span style="height: auto;">{{ p.name }}</span>
                                        </el-radio>
                                    </div>
                                    <div style="width: calc(86% - 2px);overflow:hidden; font-size: 16px; border-left: 1px solid #BABABA">
                                        <div style="overflow:hidden; float: left; width:calc(36% - 4px); border-right: 1px solid #BABABA">
                                            <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;overflow:hidden;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #BABABA'">{{ k }}</div>
                                        </div>
                                        <div style="overflow:hidden; padding-left: 2px">
                                            <el-input
	                                            v-if ="item.userType == 2 "
                                                type="textarea"
                                                v-model="p.content"
                                                :autosize="true"
                                                style="border: none;"
                                                clearable>
                                            </el-input>
                                        </div>
                                    </div>
                                </div>
                            </el-radio-group>
                        </div>
	                    <div style="width: 100%;"  v-else >
			                    <div style="width: 100%;overflow:hidden;" v-for="(p,pindex) in item.value" :key="pindex" >
				                    <div style="width: 14%; overflow: hidden; float: left;text-align: center;" v-if="p.radio==item.radio">
					                    <span v-if="p.radio==item.radio">{{p.name}}</span>
				                    </div>
				                    <div v-if="p.radio==item.radio" style="width: calc(86% - 2px);overflow:hidden; font-size: 16px; border-left: 1px solid #BABABA;">
					                    <div style="overflow:hidden; float: left; width:calc(36% - 4px); border-right: 1px solid #BABABA" >
						                    <div v-for="(k,kindex) in p.value" :key="kindex" style="line-height: 40px;height:auto;overflow:hidden;text-align: center;" :style="kindex==p.value.length-1?'':'border-bottom: 1px solid #BABABA'">
							                    {{ k }}
						                    </div>
					                    </div>
				                    </div>
			                    </div>
	                    </div>
                    </td>
                </div>
            </tr>
        </table>
        <table>
            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务输入条件</div></th>
                <td class="bold-content" style="width: 443px">
                    <div v-if="serviceInfo.input.length > 0">
                        <div v-for="(item, index) in serviceInfo.input.slice(0, serviceInfo.input.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                    </div>
                </td>
                <td class="bold" style="width: 150px">
                    <div class="select-box">
                        <el-checkbox v-model="serviceInfo.input[serviceInfo.input.length - 1].status"  :true-label="0" :false-label="1">选择</el-checkbox>
                    </div>
                </td>

                <td class="bold" style="width: 742px; border-right: none">
                    <div class="select-box">
                        <el-input
                            type="textarea"
                            v-model="serviceInfo.input[serviceInfo.input.length - 1].content"
                            :autosize="true"
                            clearable>
                        </el-input>
                    </div>
                </td>
            </tr>
            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务输出形式</div></th>
                <td class="bold-content" style="width: 443px">
                    <div v-if="serviceInfo.output.length > 0">
                        <div v-for="(item, index) in serviceInfo.output.slice(0, serviceInfo.output.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                    </div>
                </td>
                <td class="bold" style="width: 150px">
                    <div class="select-box">
                        <el-checkbox v-model="serviceInfo.output[serviceInfo.output.length - 1].status" :true-label="0" :false-label="1">选择</el-checkbox>
                    </div>
                </td>

                <td class="bold" style="width: 742px; border-right: none">
                    <div class="select-box">
                        <el-input
                            type="textarea"
                            v-model="serviceInfo.output[serviceInfo.output.length - 1].content"
                            :autosize="true"
                            clearable>
                        </el-input>
                    </div>
                </td>
            </tr>
            <tr style="width: 1500px">
                <th class="title" style="width: 150px; display: table-cell; vertical-align: middle"><div style="margin: auto 0">服务达到的标准</div></th>
                <td class="bold-content" style="width: 443px">
                    <div v-if="serviceInfo.standard.length > 0">
                        <div v-for="(item, index) in serviceInfo.standard.slice(0, serviceInfo.standard.length - 1)" :key="index">{{index + 1}}.{{item}}</div>
                    </div>
                </td>
                <td class="bold" style="width: 150px">
                    <div class="select-box">
                        <el-checkbox v-model="serviceInfo.standard[serviceInfo.standard.length - 1].status" :true-label="0" :false-label="1">选择</el-checkbox>
                    </div>
                </td>

                <td class="bold" style="width: 742px; border-right: none">
                    <div class="select-box">
                        <el-input
                            type="textarea"
                            v-model="serviceInfo.standard[serviceInfo.standard.length - 1].content"
                            :autosize="true"
                            clearable>
                        </el-input>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import Editor from "@/components/common/Editor";
export default {
    name: "ServiceTable",
    components: {Editor},
    data() {
        return {}
    },
    props: {
        serviceInfo: {
            type: Object,
            default() {
                return {
                    input: [],
                    output: [],
                    standard: []
                }
            }
        }
    },

    computed: {
        like() {
            return this.$store.state.like
        }
    },
    created: function () {

    },
    mounted: function () {
    },
    methods: {
        submitInfo() {
            this.serviceInfo.demand = this.$refs.orderChild.get_content()
            return this.serviceInfo
        },
    }
}
</script>

<style scoped>
    .service-table .service-left {
        width: 40%;
        height: 52px;
        font-size: 18px;
        line-height: 52px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-table .service-right {
        width: 60%;
        height: 52px;
        font-size: 18px;
        line-height: 52px;
        border-bottom: 1px #BABABA solid;
    }

    .service-table .bold {
        display: table-cell;
        vertical-align: middle;
        height: 52px;
        font-size: 18px;
        line-height: 52px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-table .bold-content {
        display: table-cell;
        vertical-align: middle;
        height: 30px;
        font-size: 18px;
        line-height: 30px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-table .title {
        font-size: 18px;
        border-bottom: 1px #BABABA solid;
        border-right: 1px #BABABA solid;
    }

    .service-table .select-box {
        text-align: center;
        margin: auto 0;
    }

    .service-table >>> img {
        max-width: 100%;
    }

    .service-table >>> table {
        max-width: 100%;
    }

    .service-table .el-textarea {
        border: none;
        vertical-align: middle;
    }
</style>
